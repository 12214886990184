@import "_vars";

.login_wrap {
  @include flexbox;
  justify-content: center;
  font-size: 14px;
  @include max-screen($sp_size) {
    display: block;
  }

  h2 {
    text-align: left;
    padding-bottom: 10px;
    border-bottom: 1px dotted $color_border_gray;
    font-size: 16px;
    @include max-screen($sp_size) {
      padding-bottom: 5px;
      border-bottom: none;
    }
  }

  h3 {
    font-size: 16px;
    @include max-screen($sp_size) {
      font-size: 14px;
    }
  }

  .btn {
    @include min-screen($pc_size) {
      font-size: 21px;
      font-weight: normal;
    }
    @include max-screen($sp_size) {
      font-size: 16px;
    }
  }

  .login_form {
    width: calc(50% - 10px);
    max-width: 600px;
    border: 1px solid $color_border_gray;
    padding: 15px 20px 20px;
    @include max-screen($sp_size) {
      width: 100%;
      border: none;
      padding: 0;
    }

    .form_inner {
      margin-bottom: 20px;
      @include min-screen($pc_size) {
        &:first-of-type {
          padding-top: 0;          
        }
      }

      .form_row {
        margin-bottom: 20px;

        .form_parts {
          .btn {
            margin-right: 10px;
            // width: 300px;
            width: calc(100% - 250px);
            min-width: 200px;
            max-width: 290px;
            height: 50px;
            @include max-screen($sp_size) {
              width: 160px;
              min-width: auto;
              max-width: none;
            }
          }
          .btn + input + label {
            vertical-align: middle;
            @include max-screen($sp_size) {
              font-size: 12px;              
            }
          }
        }
      }
    }

    .account_option {
      font-weight: bold;
      margin-bottom: 10px;
      @include min-screen($pc_size) {
        font-size: 16px;      
      }
    }

    .amazon_account {
      @include flexbox;
      align-items: center;
      background-color: $color_bg_gray;
      padding: 10px;
      @include max-screen($sp_size) {
        display: block;
        padding: 15px 10px;
      }

      span {
        display: block;
        margin-right: 20px;
        font-weight: bold;
        color: #000;
        @include max-screen($sp_size) {
          margin-bottom: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .login_option {
    width: calc(50% - 10px);
    max-width: 600px;
    margin-left: 20px;
    border: 1px solid $color_border_gray;
    padding: 20px;
    @include max-screen($sp_size) {
      width: 100%;
      margin-left: 0;
      margin-top: 40px;
      border: none;
      padding: 0;
    }

    h2 {
      @include max-screen($sp_size) {
        border-bottom: 1px solid $color_border_gray;        
      }
    }

    h3 {
      margin: 0 0 10px 0;
      @include max-screen($sp_size) {
        margin: 0 0 5px 0;
      }
    }

    p {
      margin-bottom: 15px;
      line-height: 1.8;
      @include max-screen($sp_size) {
        line-height: 1.4;
      }
    }

    .btn {
      width: 320px;
      height: 50px;
      margin-bottom: 30px;
      @include max-screen($sp_size) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    a:not(.btn) {
      display: block;
      margin-bottom: 20px;
    }

    .box {
      border: 1px solid $color_border_gray;
      @include max-screen($sp_size) {
        font-size: 12px;
      }

      dt {
        padding: 10px 15px 0 15px;
        font-weight: bold;
        @include max-screen($sp_size) {
          padding: 10px 10px 0 10px;
        }
      }
      dd {
        padding: 10px 15px;
        @include min-screen($pc_size) {
          line-height: 1.8;          
        }
        @include max-screen($sp_size) {
          padding: 10px;
        }

        &:not(:last-child) {
          border-bottom: 1px dotted $color_border_gray;        
        }
      }
    }
  }
}

.page_login_order {
  .login_wrap {
    margin-top: 40px;
    @include max-screen($sp_size) {
      margin-top: 0;
    }
  }
}

.page_login_new_vistor {
  .login_wrap {
    flex-direction: row-reverse;
    @include max-screen($sp_size) {
      @include flexbox;
      flex-direction: column-reverse;
    }

    .login_option {
      margin-left: 0;
      margin-right: 20px;
      @include max-screen($sp_size) {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 40px;
      }
    }
  } 
}