// Colors
$color_text: #464749;
$color_text_black: #000;
$color_text_hover: #017501;
$color_attention: #a8192e;
$color_main: #0c5641;
$color_main_dark: #3b5343;
$color_main_text: #435e54;
$color_border_gray: #d0d0d0;
$color_border_lightgray: #d9dad4;
$color_border_lime: #76c265;
$color_border_orange: #ff900d;
$color_bg_gray: #efefef;
$color_sort: #869e2c;
$color_tag: #7b916e;
$color_inputbox: #a9a9a9;
$color_btn_orange: #bd5b00;
$color_frame: #333;
$color_frame-selected: #d77500;
$color_btn-disable: #c3c3c3;


// Width
$contents_width: 1260px;
$contents_width_sp: 96%;

// Break Point
$sp_size: 767px;
$pc_size: 768px;
@mixin max-screen($size) {
  @media screen and (max-width: $size) {
    @content;
  }
}
@mixin min-screen($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

// Flexbox
@mixin flexbox {
  display: flex;
  flex-wrap: wrap;
}

// Centering  Horizontally & Vertically
@mixin centering{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// Centering  Horizontally
@mixin centering_horizontally {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// Centering  Vertically
@mixin centering_vertically {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}