.login_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .login_wrap {
    display: block;
  }
}

.login_wrap h2 {
  text-align: left;
  padding-bottom: 10px;
  border-bottom: 1px dotted #d0d0d0;
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .login_wrap h2 {
    padding-bottom: 5px;
    border-bottom: none;
  }
}

.login_wrap h3 {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  .login_wrap h3 {
    font-size: 14px;
  }
}

@media screen and (min-width: 768px) {
  .login_wrap .btn {
    font-size: 21px;
    font-weight: normal;
  }
}

@media screen and (max-width: 767px) {
  .login_wrap .btn {
    font-size: 16px;
  }
}

.login_wrap .login_form {
  width: calc(50% - 10px);
  max-width: 600px;
  border: 1px solid #d0d0d0;
  padding: 15px 20px 20px;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_form {
    width: 100%;
    border: none;
    padding: 0;
  }
}

.login_wrap .login_form .form_inner {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .login_wrap .login_form .form_inner:first-of-type {
    padding-top: 0;
  }
}

.login_wrap .login_form .form_inner .form_row {
  margin-bottom: 20px;
}

.login_wrap .login_form .form_inner .form_row .form_parts .btn {
  margin-right: 10px;
  width: calc(100% - 250px);
  min-width: 200px;
  max-width: 290px;
  height: 50px;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_form .form_inner .form_row .form_parts .btn {
    width: 160px;
    min-width: auto;
    max-width: none;
  }
}

.login_wrap .login_form .form_inner .form_row .form_parts .btn + input + label {
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_form .form_inner .form_row .form_parts .btn + input + label {
    font-size: 12px;
  }
}

.login_wrap .login_form .account_option {
  font-weight: bold;
  margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
  .login_wrap .login_form .account_option {
    font-size: 16px;
  }
}

.login_wrap .login_form .amazon_account {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #efefef;
  padding: 10px;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_form .amazon_account {
    display: block;
    padding: 15px 10px;
  }
}

.login_wrap .login_form .amazon_account span {
  display: block;
  margin-right: 20px;
  font-weight: bold;
  color: #000;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_form .amazon_account span {
    margin-bottom: 5px;
    font-size: 12px;
  }
}

.login_wrap .login_option {
  width: calc(50% - 10px);
  max-width: 600px;
  margin-left: 20px;
  border: 1px solid #d0d0d0;
  padding: 20px;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option {
    width: 100%;
    margin-left: 0;
    margin-top: 40px;
    border: none;
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option h2 {
    border-bottom: 1px solid #d0d0d0;
  }
}

.login_wrap .login_option h3 {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option h3 {
    margin: 0 0 5px 0;
  }
}

.login_wrap .login_option p {
  margin-bottom: 15px;
  line-height: 1.8;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option p {
    line-height: 1.4;
  }
}

.login_wrap .login_option .btn {
  width: 320px;
  height: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option .btn {
    width: 100%;
    margin-bottom: 20px;
  }
}

.login_wrap .login_option a:not(.btn) {
  display: block;
  margin-bottom: 20px;
}

.login_wrap .login_option .box {
  border: 1px solid #d0d0d0;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option .box {
    font-size: 12px;
  }
}

.login_wrap .login_option .box dt {
  padding: 10px 15px 0 15px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option .box dt {
    padding: 10px 10px 0 10px;
  }
}

.login_wrap .login_option .box dd {
  padding: 10px 15px;
}

@media screen and (min-width: 768px) {
  .login_wrap .login_option .box dd {
    line-height: 1.8;
  }
}

@media screen and (max-width: 767px) {
  .login_wrap .login_option .box dd {
    padding: 10px;
  }
}

.login_wrap .login_option .box dd:not(:last-child) {
  border-bottom: 1px dotted #d0d0d0;
}

.page_login_order .login_wrap {
  margin-top: 40px;
}

@media screen and (max-width: 767px) {
  .page_login_order .login_wrap {
    margin-top: 0;
  }
}

.page_login_new_vistor .login_wrap {
  flex-direction: row-reverse;
}

@media screen and (max-width: 767px) {
  .page_login_new_vistor .login_wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.page_login_new_vistor .login_wrap .login_option {
  margin-left: 0;
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .page_login_new_vistor .login_wrap .login_option {
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 40px;
  }
}
